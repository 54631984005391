<template>
  <div class="content-container">
    <PageTitle :title="$t('SCHOOLS')" class="page-title"/>

    <h1 class="page-section-title section-title">{{ $t('SCHOOL_CREATE.NEW') }}</h1>
    <BackButton :text="$t('BACK_OVERVIEW')" :route-name="ROUTE_NAMES_HELPDESK.SCHOOL_OVERVIEW"/>
    <InformationBox
      custom-class="school-create-info"
      class="info-box"
      :display-icon="true">
      <template #text>
        <p class="text">{{ $t('SCHOOL_CREATE.INFO_BOX') }} <br/>
        </p>
      </template>
    </InformationBox>

    <!-- Form -->
    <FormWrapper :send-form-data="validateData" class="page-form">
      <template #form-content>
        <!-- School Name -->
        <InputField :field-title="$t('SCHOOL_CREATE.SCHOOL_NAME')"
                    :field-name="'name'"
                    :rules="'required'"
                    :type="'text'"
                    :placeholder="$t('SCHOOL_CREATE.SCHOOL_NAME_PLACEHOLDER')"
                    :cy-selector="'name-input'"
                    :api-error-text="''"
                    class="form-element"/>


        <div class="two-column-wrapper address-columns">
          <!-- Address -->
          <InputField :field-title="$t('SCHOOL_CREATE.STREET')"
                      :field-name="'streetName'"
                      :rules="'required'"
                      :type="'text'"
                      :placeholder="$t('SCHOOL_CREATE.STREET_PLACEHOLDER')"
                      :cy-selector="'name-input'"
                      :api-error-text="''"
                      class="form-element-column-wide"/>

          <!-- Address -->
          <InputField :field-title="$t('SCHOOL_CREATE.HOUSE_NUMBER')"
                      :field-name="'houseNumber'"
                      :rules="'required'"
                      :type="'text'"
                      :placeholder="1"
                      :cy-selector="'name-input'"
                      :api-error-text="''"
                      class="form-element-column"/>
        </div>
        <!-- BRIN Number -->
        <InputField :field-title="$t('SCHOOL_CREATE.BRIN')"
                    :field-name="'brin'"
                    :rules="'required'"
                    :type="'text'"
                    :placeholder="$t('SCHOOL_CREATE.BRIN_PLACEHOLDER')"
                    :cy-selector="'name-input'"
                    :api-error-text="brinApiError"
                    class="form-element"/>

        <div class="two-column-wrapper address-columns">
          <!-- Postcode -->
          <InputField :field-title="$t('POSTCODE')"
                      :field-name="'postalCode'"
                      :rules="'required'"
                      :type="'text'"
                      :placeholder="$t('POSTCODE_PLACEHOLDER')"
                      :cy-selector="'students-input'"
                      :api-error-text="''"
                      class="form-element-column"/>
          <!-- City -->
          <InputField :field-title="$t('CITY')"
                      :field-name="'city'"
                      :rules="'required'"
                      :type="'text'"
                      :placeholder="$t('CITY_PLACEHOLDER')"
                      :cy-selector="'city-input'"
                      :api-error-text="''"
                      class="form-element-column"/>
        </div>

        <!-- Province -->
        <InputField :field-title="$t('PROVINCE')"
                    :field-name="'province'"
                    :rules="'required'"
                    :type="'text'"
                    :placeholder="$t('PROVINCE_PLACEHOLDER')"
                    :cy-selector="'name-input'"
                    :api-error-text="''"
                    class="form-element"/>


        <!-- Group -->
        <Dropdown id="schoolType"
                  :field-title="$t('SCHOOL_CREATE.SCHOOL_TYPE')"
                  :dropdown-options="schoolTypeOptions"
                  :placeholder="$t('SCHOOL_CREATE.SCHOOL_TYPE_PLACEHOLDER')"
                  :field-name="'schoolTypeName'"
                  :callback="() => {}"
                  :api-error-text="''"
                  class="form-element"/>
      </template>

      <template #button-submit>
        <button type="submit" class="pink-button submit-button">
          {{ $t('SCHOOLS_HELPDESK.CREATE_SCHOOL') }}
        </button>
      </template>

    </FormWrapper>
  </div>
</template>

<script>
import PageTitle from '@/components/elements/texts/PageTitle'
import InputField from '@/components/yo-form/InputField'
import FormWrapper from '@/components/yo-form/FormWrapper'
import { ROUTE_NAMES_HELPDESK } from '@/router/modules/helpdesk'
import BackButton from '@/components/elements/BackButton'
import InformationBox from '@/components/elements/InformationBox'
import Dropdown from '@/components/yo-form/Dropdown'
import { ref } from '@vue/runtime-core'
import { useStore } from 'vuex'
import { CREATE_SCHOOL } from '@/store/modules/helpdesk/actions'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import { schoolTypes } from '@/utils/data/schoolTypes'

export default {
  name: 'SchoolCreate',
  components: {
    InformationBox,
    BackButton,
    FormWrapper,
    PageTitle,
    InputField,
    Dropdown,
  },
  setup() {
    const { t } = useI18n({})
    const schoolTypeOptions = schoolTypes;
    const store = useStore()
    const router = useRouter()
    const brinApiError = ref('')

    function reverseLookup(list, name) {
      for (let i = 0; i < list.length; i++) {
        if (list[i].name === name) {
          return list[i].id
        }
      }
    }


    /** Form **/
    function validateData(data) {

      data.schoolTypeId = reverseLookup(schoolTypeOptions, data.schoolTypeName)
      store.dispatch(CREATE_SCHOOL, data).then(response => {
        if (response.status === 200 || response.status === 201) {
          router.push({ name: ROUTE_NAMES_HELPDESK.SCHOOL_OVERVIEW })
        } else {
          switch (response.data.error) {
            case 'brin_already_in_use':
              brinApiError.value = t('API_ERRORS.BRIN_ALREADY_IN_USE')
              break
            default:
              brinApiError.value = t('API_ERRORS.UNKNOWN_ERROR')
              break
          }
        }
      })
    }

    return {
      ROUTE_NAMES_HELPDESK,
      schoolTypeOptions,
      /** Form **/
      validateData,
      brinApiError
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";

.address-columns {
  margin-bottom: rem(28);
}

.info-box {
  margin-bottom: rem(24);
  margin-top: rem(24);
}
</style>
