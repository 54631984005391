<template>
  <div :class="customClass" class="information-box">
    <inline-svg v-if="displayIcon"
                :src="require('../../assets/icons/icn_instruction.svg')"
                fill="#000000"
                stroke="#000000"
                class="info-icon"
    ></inline-svg>
    <slot name="text"/>
  </div>
</template>

<script>

import InlineSvg from 'vue-inline-svg'

export default {
  name: 'InformationBox',
  components: {
    InlineSvg
  },
  props: {
    displayIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    customClass: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>

<style scoped lang="scss">
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";


.information-box {
  border-radius: rem(10);
  background: rgb(65,55,114);
  background: linear-gradient(90deg, rgba(65,55,114,1) 0%, rgba(72,74,143,1) 100%);
  padding: rem(17) rem(16) rem(17) rem(56);
  position: relative;
  min-width: rem(250);
}

.info-icon {
  @include position(absolute, $top: rem(18), $left: rem(16));
  height: rem(24);
  width: rem(24);
  :deep g{
    fill: white;
  }
}

:deep .text {
  color: white;
  font-size: rem(16);
  letter-spacing: 0;
  line-height: rem(22);
  text-align: left;

  .link {
    color: white;
    font-weight: 600;
    text-decoration: underline;
  }
}

.instruction-box {
  max-width: rem(403);
  padding: rem(28);

  &:not(:last-of-type) {
    margin-bottom: rem(20);
  }
}

.school-create-info {
  background: #A9B6CD;
  width: rem(492);
}

.dia-info{
  color:white;
  background:#A9B6CD;
  height: fit-content;
  min-width: rem(300);
  margin-top: rem(28)
}
</style>
